import * as React from "react"
import { useState, useEffect } from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import PageTop from "../components/pageTop"

import scrollTo from 'gatsby-plugin-smoothscroll'

import { motion, AnimatePresence } from 'framer-motion'
import { wrap } from "@popmotion/popcorn";

//import '../assets/main.scss'

import picEsayari1 from '../images/kodawari-esayari1.jpg'
import picEsayari2 from '../images/kodawari-esayari2.jpg'
import picEsayari3 from '../images/kodawari-esayari3.jpg'

import picNoujyou1 from '../images/kodawari-noujyou1.jpg'
import picNoujyou2 from '../images/kodawari-noujyou2.jpg'
import picNoujyou3 from '../images/kodawari-noujyou3.jpg'

import picEdaniku1 from '../images/kodawari-edaniku1.jpg'
import picEdaniku2 from '../images/kodawari-edaniku2.jpg'
import picEdaniku3 from '../images/kodawari-edaniku3.jpg'

import picUshikai1 from '../images/kodawari-ushikai1.jpg'
import picUshikai2 from '../images/kodawari-ushikai2.jpg'
import picUshikai3 from '../images/kodawari-ushikai3.jpg'

import picBG from '../images/bg700x467.png'

const imgshiiku = [picEsayari1, picEsayari2, picEsayari3]
const imgsNoujyou = [picNoujyou1, picNoujyou2, picNoujyou3]
const imgsEdaniku = [picEdaniku1, picEdaniku2, picEdaniku3]
const imgsUshikai = [picUshikai1, picUshikai2, picUshikai3]

const imageAmount = 3

const variants = {
  enter: (direction) => {
    return {
      x: direction > 0 ? 1 : -1000,
      opacity: 0
    }
  },
  center: {
    zIndex: 1,
    x: 0,
    opacity: 1
  },
  exit: (direction) => {
    return {
      zIndex: 0,
      x: direction < 0 ? 1000 : -1,
      opacity: 0
    }
  }
}

const Kodawari = () => {
  const [[page, direction], setPage] = useState([0, 0]);
  const imageIndex = wrap(0, imageAmount, page);

  // この関数をここに置いておくとwarningがでるからuseEffectの中に入れる
  // const paginate = (newDirection) => {
  //   setPage([page + newDirection, newDirection]);
  // };

  // timer
  useEffect( () => {
    const paginate = (newDirection) => {
      setPage([page + newDirection, newDirection])
    }

    const intervalId = setInterval(function() {
      paginate( 1 )
    }, 3000)

    return () => {clearInterval(intervalId)}
  }, [page])
  
  return (

    <Layout>
      <Seo title="佐々畜産のこだわり" />
      <PageTop classname="kodawari" title="こだわりの牛飼い" />

      <div id="main">
        <section className="index-section kodawari">
            <div className="content viewHeight100">
              <div className="centering-container text-center">
                <p className="margin-bottom10 text-justify">昔からきれいな水が湧くところには牛飼いが多いと言われています。
                これは、良質な水が豊富な地域は稲作が盛んであり、米を収穫した後に残った良質な稲ワラが牛への大切な飼料となることに依ります。
                さらに、稲ワラを食べた牛から出る糞を堆肥として田畑に戻すことで稲作へと還元する、これを循環型農業と言います。
                このように「稲作」と「牛飼い」には密接な関係があり、米作りの盛んな日本では牛の存在は大きな意義を持っているのです。</p>
                <div className="display-block margin-top30">
                  <button type="button" className="material-icons btn-next cp_anime08" onClick={() => scrollTo('#kodawari')}>expand_more</button>
                </div>
              </div>
            </div>
        </section>

        <section className="index-section kodawari" id="kodawari">
          <div className="baseBox">
            <img src={picBG} alt="ダミーの背景" />
            <div className="imageBox">
                <AnimatePresence initial={false} custom={direction}>
                  <motion.img
                    className="display-block"
                    key={page}
                    src={imgsNoujyou[imageIndex]}
                    custom={direction}
                    variants={variants}
                    initial="enter"
                    animate="center"
                    exit="exit"
                    transition={{
                      x: { type: "spring", stiffness: 300, damping: 200 },
                      opacity: { duration: 0.8 }
                    }}
                    alt="農場のこだわりの写真"
                  />
                </AnimatePresence>
            </div>
          </div>
            
          <div className="content">
            <div className="centering-container">
              <h2>農場のこだわり</h2>
              <p>菊地市は、阿蘇外輪山の北西部の標高500ｍ～800ｍの間に位置する菊池渓谷から湧き出る良質な水（名水100選）を水源とする一級河川の菊池川が流れています。その下流域に存在する佐々畜産では、新鮮で良質な地下水を牛達への飲料水とし、かつ稲作が盛んな菊池市で取れる良質な稲ワラを粗飼料として使用しています。</p>
              {/* <div className="button btn-sticky" onClick={() => scrollTo('#ushikai')}>次へ</div> */}
            </div>
          </div>
        
        </section>

        <section className="index-section kodawari">
          <div className="baseBox" id="ushikai">
            <img src={picBG}　alt="ダミーの背景" />
            <div className="imageBox">
                <AnimatePresence initial={false} custom={direction}>
                  <motion.img
                    className="display-block"
                    key={page}
                    src={imgsUshikai[imageIndex]}
                    custom={direction}
                    variants={variants}
                    initial="enter"
                    animate="center"
                    exit="exit"
                    transition={{
                      x: { type: "spring", stiffness: 300, damping: 200 },
                      opacity: { duration: 0.8 }
                    }}
                    alt="牛飼いのこだわりの写真"

                  />
                </AnimatePresence>
            </div>
          </div>
          <div className="content">
            <div className="centering-container">
              <h2>牛飼いのこだわり</h2>
              <p>現在、肥育牛250頭・繁殖母牛50頭を飼養しており、仔牛を産ませてからお肉になるまでを同一農場で行う一貫経営を主体としています。農場内で産まれた牛達は、ストレスなく健康的にすくすく育っていきます。さらに経営者自らが、獣医師として徹底的な衛生管理を行い、安心安全な牛肉を提供することにこだわっています。</p>
              {/* <div className="button btn-sticky" onClick={() => scrollTo('#hiiku')}>次へ</div> */}
            </div>
          </div>
        </section>

        <section className="index-section kodawari">
        <div className="baseBox" id="hiiku">
            <img src={picBG} alt="ダミーの背景"　/>
            <div className="imageBox">
                <AnimatePresence initial={false} custom={direction}>
                  <motion.img
                    className="display-block"
                    key={page}
                    src={imgshiiku[imageIndex]}
                    custom={direction}
                    variants={variants}
                    initial="enter"
                    animate="center"
                    exit="exit"
                    transition={{
                      x: { type: "spring", stiffness: 300, damping: 200 },
                      opacity: { duration: 0.8 }
                    }}
                    alt="肥育へのこだわりの写真"

                  />
                </AnimatePresence>
            </div>
          </div>
          <div className="content">
            <div className="centering-container">
              <h2>肥育へのこだわり</h2>
              <p>大規模な肥育農場では、通常、作業効率の良いリフト給餌機や自動給餌器を用いて飼料給与を行います。しかし佐々畜産では昔ながらの「手やり」の給餌方法にこだわっており、農場スタッフがその日の牛たちの体調を見ながら日々の餌の給与量を調節しています。</p>
              {/* <div className="button btn-sticky" onClick={() => scrollTo('#edaniku')}>次へ</div> */}
            </div>
          </div>
        </section>

        <section className="index-section kodawari">
        <div className="baseBox" id="edaniku">
            <img src={picBG} alt="ダミーの背景" />
            <div className="imageBox">
                <AnimatePresence initial={false} custom={direction}>
                  <motion.img
                    key={page}
                    src={imgsEdaniku[imageIndex]}
                    custom={direction}
                    variants={variants}
                    initial="enter"
                    animate="center"
                    exit="exit"
                    transition={{
                      x: { type: "spring", stiffness: 300, damping: 200 },
                      opacity: { duration: 0.8 }
                    }}
                    alt="枝肉へのこだわりの写真"
                  />
                </AnimatePresence>
            </div>
          </div>
          <div className="content">
            <div className="centering-container">
              <h2>枝肉へのこだわり</h2>
              <p className="margin-bottom30">佐々畜産のお肉は、熊本の牛肉ブランド「和王」として皆様に届きます。「和王」とは、熊本経済連が厳選した単味飼料を独自にブレンドした配合飼料である「とくせん」シリーズが給与された生後月齢28ヵ月齢以上の牛で、なおかつ最上級格付け5等級と上位格付け4等級を獲得した枝肉のみに許される呼称です。
              </p>
              <a style={{display: 'block', marginTop: '0px', marginBottom: '20px', color: 'rgb(138, 174, 221)'}} href="https://youtu.be/W66pjBlBBMc" className="button">和王ブランディング動画を観る<i className="material-icons font100">link</i></a>
              {/* <div className="button btn-sticky" onClick={() => scrollTo('#banner')}>上へ</div><Link to="/about#jyusyourireki" className="button btn-sticky">受賞履歴</Link> */}
            </div>
          </div>
        </section>
      </div>
    </Layout>
  )
}

  export default Kodawari